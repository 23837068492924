import { deleteNote, getNotes } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { formatDateRender, usePasswordConfirm } from 'helpers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@material-ui/core';
import styles from '../postNote.module.css';

export default function Note(props) {
	const { setSnackbar, setLoader } = useCreateDispatches();
	const onPasswordConfirm = usePasswordConfirm();
	const { id, createdAt, note, postId } = props;
	const name = props.metadata?.createdName
		? props.metadata?.createdName
		: 'Unknown';

	const handleDeleteNote = (pass) => {
		setLoader(true);
		deleteNote(id, pass)
			.then(() => {
				getNotes(postId);
				setSnackbar({
					variant: 'success',
					message: 'Success, note has been deleted',
				});
			})
			.catch((e) => {
				setSnackbar({
					variant: 'error',
					message:
						'Sorry there was a problem deleting this note, please try again later',
				});
			})
			.finally(() => setLoader(false));
	};

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`note-${id}-content`}>
				<Typography className={styles.postNoteContainerHeading}>
					{formatDateRender(createdAt)} - Created By {name}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={styles.accordionDetails}>
				<Typography>{note}</Typography>
				<DeleteIcon
					className={styles.noteDeleteIcon}
					onClick={() =>
						onPasswordConfirm(
							(e) => handleDeleteNote(e),
							'This is a permanent option and cannot be undone. Please confirm your password.'
						)
					}
				/>
			</AccordionDetails>
		</Accordion>
	);
}
