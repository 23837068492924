import { withRouter, Link } from 'react-router-dom';
import useLoginState from '../utils/useLoginState';
import { PrimaryButton, TextField, PasswordField } from 'components';
import styles from '../login.module.css';
import TwoFactorModal from './TwoFactorModal';

export function LoginForm() {
	const { username, password, login } = useLoginState();
	const enterSubmit = (e) => {
		if (e.keyCode === 13) {
			login();
		}
	};

	return (
		<>
			<TwoFactorModal username={username} password={password} />
			<div className={styles.loginForm}>
				<TextField
					className={styles.loginInput}
					variant='outlined'
					id='username-input'
					{...username}
					onKeyDown={(e) => enterSubmit(e)}
					autoFocus={true}
					name='username'
				/>
				<PasswordField
					className={styles.loginInput}
					variant='outlined'
					id='password-input'
					{...password}
					onKeyDown={(e) => enterSubmit(e)}
					margin='normal'
					name='password'
				/>
			</div>
			<Link className={styles.loginLink} to='/login/passwordrequest/'>
				Forgot Password?
			</Link>
			<div className={styles.loginButtonContainer}>
				<PrimaryButton onClick={login}>Log in</PrimaryButton>
			</div>
			<p className={styles.loginTermsContainer}>
				By logging in, you agree to the{' '}
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://ella.care/privacy'>
					Privacy Policy
				</a>{' '}
				and{' '}
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://ella.care/terms'>
					Terms of Service
				</a>{' '}
				for Ella
			</p>
		</>
	);
}

export default withRouter(LoginForm);
