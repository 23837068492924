import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar, setLoader } from 'redux/index';
import { usePasswordConfirm } from 'helpers';
import * as services from 'services';
import { Tooltip } from '@material-ui/core';
import { CloudDownload, DeleteForever } from '@material-ui/icons';
import { PrimaryButton, DatePicker, TextField } from 'components';
import styles from '../vault.module.css';

const VaultFileOther = (props) => {
	const child = useSelector((state) => state.lifebooks.child);
	const user = useSelector((state) => state.user.user);

	const onPasswordConfirm = usePasswordConfirm();

	const [accessDate, setAccessDate] = useState(new Date());
	const [label, setLabel] = useState(props?.file?.label);

	const dispatch = useDispatch();

	const getAccessDate = () => {
		if (props?.file?.accessDate) {
			setAccessDate(props.file.accessDate);
			return;
		}

		if (child.dob) {
			const date = child.dob;
			const newDate = new Date(date).setFullYear(
				new Date(date).getFullYear() + 18
			);
			const finalDate = new Date(newDate);
			setAccessDate(finalDate);
			return;
		}

		const date = new Date();
		setAccessDate(date);
		return;
	};

	useEffect(() => {
		getAccessDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [child, props.file]);

	const handleDate = (e) => {
		setAccessDate(e);
	};

	const handleFileDownload = () => {
		if (props?.file?.url) {
			window.location = props?.file?.url;
		} else {
			dispatch(
				setSnackbar(
					{ variant: 'error', message: 'No File To Download' },
					{ log: true }
				)
			);
		}
	};

	const handleFileDelete = (pass) => {
		if (props?.file?.url) {
			dispatch(setLoader(true));
			services
				.deleteVaultFile(props?.file?.id, pass)
				.then(() => {
					services.getChildVaultFiles(child.id);
				})
				.catch((err) => {
					dispatch(setLoader(false));
					dispatch(
						setSnackbar(
							{ variant: 'error', message: err.response?.data.message },
							{ log: true }
						)
					);
				})
				.finally(() => {
					dispatch(
						setSnackbar({
							variant: 'success',
							message: 'Succesfully deleted vault post',
						})
					);
					dispatch(setLoader(false));
				});
		} else {
			dispatch(
				setSnackbar(
					{ variant: 'error', message: 'No File To Delete' },
					{ log: true }
				)
			);
		}
	};

	const handleSubmit = () => {
		const newAccessDate = accessDate !== props?.file?.accessDate;
		const newLabel = label !== props?.file?.label;
		dispatch(setLoader(true));
		if (newLabel || newAccessDate) {
			services.editVaultFile(props?.file?.id, accessDate, label).then((e) =>
				dispatch(
					setSnackbar({
						variant: 'success',
						message: 'Edited Vault File',
					})
				)
			);
			dispatch(setLoader(false));
		} else {
			dispatch(setLoader(false));
			dispatch(
				setSnackbar({
					variant: 'error',
					message: 'Edit file or access date to submit',
				})
			);
		}
	};
	const today = new Date();

	const hasFile = props.newFile || props?.file?.url;
	const hasAccess =
		props?.file?.accessDate && new Date(props?.file?.accessDate) < today
			? true
			: false;

	if (user.type === 'child' && !hasAccess) {
		return <></>;
	}

	if (user.type === 'child' && hasAccess && hasFile) {
		return (
			<div className={styles.childFilesOther}>
				<Tooltip title={'Download file'} placement='top-start'>
					<CloudDownload
						className={styles.downloadIconActiveChild}
						onClick={props.handleFileDownload}
					/>
				</Tooltip>
				<>{props?.file?.label}</>
			</div>
		);
	}

	return (
		<div className={styles.vaultFileItemContainer}>
			<div className={styles.otherTextLabel}>
				<TextField
					className={styles.fileName}
					variant='outlined'
					label='File Name:'
					required={true}
					value={label}
					onChange={(e) => setLabel(e?.target?.value)}
				/>
			</div>
			<div className={styles.vaultDateContainerOther}>
				<DatePicker
					onChange={(e) => handleDate(e)}
					value={accessDate}
					required
					disableFuture={false}
					label={'Access Date'}
				/>
			</div>

			<div className={styles.vaultIconContainer}>
				<Tooltip title={'Download file'} placement='top-start'>
					<CloudDownload
						className={styles.downloadIconActive}
						onClick={handleFileDownload}
					/>
				</Tooltip>
				<Tooltip title={'Delete File'} placement='top-start'>
					<DeleteForever
						className={styles.downloadIconActive}
						onClick={() => onPasswordConfirm((e) => handleFileDelete(e))}
					/>
				</Tooltip>
			</div>

			<div className={styles.submitButtonContainer}>
				<PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
			</div>
		</div>
	);
};

export default VaultFileOther;
