import { useDispatch } from 'react-redux';
import { setLoader, setSnackbar } from 'redux/index';
import { revokeAuditor } from 'services';
import { AuditorListItem } from './';
import { FormSectionHeader } from 'components';
import styles from './childAudit.module.css';

export default function AuditorList({ auditorList = [], setAuditorList }) {
	const dispatch = useDispatch();
	const dateNow = new Date();

	const removeFromList = (index) => {
		return auditorList.filter((val, i) => i !== index);
	};

	const onRevokeAuditor = async (id, index, pass) => {
		revokeAuditor(id, pass)
			.then(() => {
				setAuditorList(removeFromList(index));
			})
			.catch((err) => {
				dispatch(
					setSnackbar(
						{ variant: 'error', message: err.response?.data.message },
						{ log: true }
					)
				);
			})
			.finally(() => {
				dispatch(setLoader(false));
			});
	};

	const listMapper = (auditor, index) => {
		const { id, expiresAt } = auditor;
		const expired = new Date(expiresAt) < dateNow;
		const date = new Date(expiresAt);
		let dateDMY = `${date.getMonth() + 1}-${date.getDate()}
		-${date.getFullYear()}`;

		return (
			<AuditorListItem
				auditor={auditor}
				date={dateDMY}
				key={`${index}-${id}`}
				handleRevoke={
					!expired ? (pass) => onRevokeAuditor(id, index, pass) : null
				}
			/>
		);
	};

	const displayedList = auditorList.map(listMapper);

	return auditorList.length > 0 ? (
		<div className={styles.auditorListRoot}>
			<div className={styles.auditorListContent}>
				<FormSectionHeader hideTop>
					<div className={styles.auditorListHeader}>
						<div>Auditors</div>
					</div>
				</FormSectionHeader>
				{displayedList}
			</div>
		</div>
	) : null;
}
