import { validateEmail } from './utils/validate';

export const handleInvitedUserValidation = (reqBody, type) => {
	if (type === 'manager') {
		if (!reqBody.branch) {
			return { isValid: false, message: 'Branch is required' };
		}
	}
	if (type === 'socialWorker' || type === 'mentor') {
		if (!reqBody.manager) {
			return { isValid: false, message: 'Manager is required' };
		}
	}

	if (!reqBody.email) {
		return { isValid: false, message: 'Email is required' };
	}

	const checkEmail = validateEmail(reqBody.email);

	if (!checkEmail) {
		return { isValid: false, message: 'Email is not valid' };
	}

	if (!reqBody.firstName) {
		return { isValid: false, message: 'First name is required' };
	}

	if (!reqBody.lastName) {
		return { isValid: false, message: 'Last name is required' };
	}

	return { isValid: true, message: 'Success' };
};
