import { useHistory, useParams } from 'react-router-dom';
import * as service from 'services';
import * as hook from 'helpers/hooks';
import { errors } from 'helpers';
import { PrimaryButton, SecondaryButton, DeleteButton } from 'components';

export const useGetSocialWorkerButtons = (getBody, resetListChanges) => {
	const history = useHistory();
	const { setLoader, setSnackbar, setSnackbarError } =
		hook.useCreateDispatches();
	const params = useParams();
	const { swId } = params;
	const onPasswordConfirm = hook.usePasswordConfirm();
	const roleSelectRedirect = hook.useRoleSelectRedirect();

	const errorHandler =
		(error) =>
		({ response }) => {
			setSnackbarError({ response, ...error }, { log: true });
		};

	function CancelButton() {
		const onClick = () => history.goBack();
		return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
	}

	function ArchiveButton() {
		const onDelete = async (pass) => {
			setLoader(true);
			service
				.archiveAgencyUser(swId, pass)
				.then(async () => {
					setSnackbar({
						variant: 'success',
						message: 'Social Worker removed successfully',
					});
					await service.getAgencyUsers();
					await service.setChildrenList();
					history.push('/socialworkers/');
				})
				.catch(errorHandler(errors.USER_ARCHIVE('SW')))
				.finally(() => {
					setLoader(false);
				});
		};

		return (
			<DeleteButton onClick={() => onPasswordConfirm((e) => onDelete(e))}>
				Delete
			</DeleteButton>
		);
	}

	function SaveButton() {
		const onClick = async () => {
			setLoader(true);
			const reqBody = getBody();

			service
				.updateAgencyUser(reqBody)
				.then(async () => {
					await service.getAgencyUsers();
					await service.setChildrenList();
					if (reqBody.role) {
						roleSelectRedirect(reqBody.role, reqBody.id);
					} else {
						resetListChanges();
					}
				})
				.catch(errorHandler(errors.USER_SAVE('SW')))
				.finally(() => {
					setLoader(false);
				});
		};

		return <PrimaryButton onClick={onClick}>Save Changes</PrimaryButton>;
	}

	return [
		<CancelButton key='cancel' />,
		<ArchiveButton key='archive' />,
		<SaveButton key='save' />,
	];
};
