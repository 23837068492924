import { useHistory } from 'react-router-dom';
import { setLoader, store } from 'redux/index';
import * as services from 'services';
import { usePasswordConfirm } from 'helpers';
import { DeleteButton } from 'components';
import styles from '../CasaUser.module.css';

const RemoveCasaUserButton = (props) => {
	const onPasswordConfirm = usePasswordConfirm();

	const history = useHistory();

	const onDeleteCasaUser = async (pass) => {
		store.dispatch(setLoader(true));
		await services.deleteUser(props.userId, pass);
		store.dispatch(setLoader(false));
		history.goBack();
	};

	return (
		<div className={styles.casaUserButton}>
			<div>
				<div className={styles.casaBoldLabel}>Delete Casa User</div>
				<div>This option will Delete the Casa User</div>
			</div>
			<div className={styles.casaButtonWrap}>
				<DeleteButton
					onClick={() =>
						onPasswordConfirm(
							(e) => onDeleteCasaUser(e),
							'This is a permanent option and cannot be undone. Please confirm your password.'
						)
					}>
					Delete Casa User
				</DeleteButton>
			</div>
		</div>
	);
};

export default RemoveCasaUserButton;
