import { apiV2 } from '../axios';

export const inviteAuditor = async (params, pass) => {
	// uses auth-verify header //

	const expiresAt = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
	const reqBody = { ...params, expiresAt, pass };
	return await apiV2.post('/auditor', reqBody);
};

export const getAuditors = async (childId) => {
	const dateNow = new Date();
	return await apiV2
		.get(`/auditor?childId=${childId}`, { childId })
		.then((res) => {
			const filteredArr = [...res.data].filter((val) => {
				const expired = new Date(val.expiresAt) < dateNow;
				return val.childId === childId && !expired;
			});
			res.data = filteredArr;
			return res;
		});
};

export const revokeAuditor = async (id, pass) => {
	// uses auth-verify header //

	return await apiV2.delete(`/auditor/${id}`, { pass });
};
