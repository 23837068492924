import { withRouter, Redirect, Switch, Route } from 'react-router-dom';
import * as LoginComp from './components';
import styles from './login.module.css';

export function Login() {
	return localStorage.getItem('token') ? (
		<Redirect to='/lifebooks' />
	) : (
		<div className={styles.loginPageRoot}>
			<div className={styles.loginSectionOne} />
			<div className={styles.loginSectionTwo}>
				<div className={styles.loginWrapper}>
					<form className={styles.loginContainer}>
						<img
							className={styles.loginLogo}
							src={window.location.origin + '/assets/login-logo.svg'}
							alt='Ella Logo'
						/>
						<Switch>
							<Route path='/reset/' component={LoginComp.PasswordReset} />
							<Route
								path='/ownership/'
								component={LoginComp.OwnershipCodeEntry}
							/>
							<Route
								path='/login/passwordrequest/'
								component={LoginComp.PasswordRequest}
							/>
							<Route path='/login/' component={LoginComp.LoginForm} />
						</Switch>
						<LoginComp.LoginLinks />
					</form>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Login);
