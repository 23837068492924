import { apiV2 } from '../axios';
import {
	store,
	setCasa,
	setCasaUsers,
	setCasaConnections,
	setContributorChildren,
} from '../redux';
import { getUserFromToken } from './';
import { listSortPersonByName, showSnack } from '../helpers';

export const getCasa = async () => {
	const { casaId } = await getUserFromToken();

	if (casaId) {
		const response = await apiV2.get(`/casa/${casaId}`).catch(() => {
			store.dispatch(setCasa('error'));
			return;
		});
		response?.data
			? store.dispatch(setCasa(response.data))
			: store.dispatch(setCasa('error'));
	}
	return;
};

export const getCasaForProfile = async (casaId) => {
	if (casaId) {
		const response = await apiV2.get(`/casa/${casaId}`).catch(() => {
			return;
		});
		return response?.data;
	} else {
		return;
	}
};

export const editCasa = async (casaId, requestBody) => {
	let response = await apiV2
		.put(`/casa/${casaId}`, requestBody)
		.catch((err) => showSnack('error', err.message));
	if (response.data) {
		store.dispatch(setCasa(response.data));
		showSnack('success');
		return response.data;
	} else {
		showSnack('error');
		return;
	}
};

export const getCasaUsers = async (id) => {
	const { casaId } = await getUserFromToken();
	const checkedId = id ? id : casaId;
	const response = await apiV2.get(`/user?casaId=${checkedId}`).catch(() => {
		store.dispatch(setCasaUsers('error'));
	});

	if (response?.data) {
		const sortedData = listSortPersonByName(response.data, 'lastname', 'email');
		store.dispatch(setCasaUsers(sortedData));
	} else {
		store.dispatch(setCasaUsers('error'));
	}

	return;
};

export const inviteCasaUser = async (form) => {
	const response = await apiV2.post(`/user`, form).catch(() => {
		showSnack('error');
		return;
	});
	showSnack(
		'success',
		'Success this user will receive an email to create an account.'
	);

	response && getCasaUsers();

	return;
};

export const changeCasaRole = async (id, role) => {
	const response = await apiV2.put(`/user/${id}`, { role: role }).catch(() => {
		showSnack('error');
	});
	if (response?.data) {
		getCasaUsers();
		showSnack('success');
		return response.data;
	} else {
		showSnack('error');
	}
	return;
};

export const requestCasaConnection = async (email) => {
	const response = await apiV2
		.post('/casa/connection', { email: email })
		.catch(() => {
			showSnack('error');
			return;
		});

	response && getCasaConnections();
	response
		? showSnack('success')
		: showSnack(
				'error',
				'CASA admin could not be located with the email provided'
		  );
	return;
};

export const getCasaConnections = async () => {
	const userToken = await getUserFromToken();

	const response = await apiV2.get('/casa/connection').catch(() => {
		store.dispatch(setCasaConnections('error', userToken.type));
	});

	response?.data
		? store.dispatch(setCasaConnections(response.data, userToken.type))
		: store.dispatch(setCasaConnections('error', userToken.type));

	return;
};

export const approveCasaConnection = async (id) => {
	const response = await apiV2
		.post(`/casa/connection/${id}/approve`)
		.catch(() => {
			showSnack('error');
			return;
		});

	response?.data ? showSnack('success') : showSnack('success');
	response?.data && getCasaConnections();
	return;
};

export const rejectCasaConnection = async (id) => {
	const response = await apiV2
		.post(`/casa/connection/${id}/reject`)
		.catch(() => {
			showSnack('error');
			return;
		});

	response?.data && getCasaConnections();
	response?.data ? showSnack('success') : showSnack('error');

	return;
};

export const addChildToCasaUser = async (userId, childIds, action, pass) => {
	// uses auth-verify header //

	const userToken = await getUserFromToken();

	const response = await apiV2
		.put(
			`/user/${userId}`,
			action === 'add'
				? { childIdsToAdd: childIds, pass }
				: { childIdsToRemove: childIds, pass }
		)
		.catch(() => showSnack('error'));

	response?.data && userToken?.type === 'casauser' && getCasaUsers();
	response?.data ? showSnack('success') : showSnack('error');

	return response;
};

export const addChildToCasa = async (casa, childId, action, pass) => {
	// uses auth-verify header //

	const response = await apiV2
		.put(
			`/user/${childId}`,
			action === 'add' ? { casaId: casa, pass } : { casaId: '', pass }
		)
		.catch(() => showSnack('error'));

	response?.data ? showSnack('success') : showSnack('error');

	return response;
};

export const getCasaRelation = async (id) => {
	const relationType = 'casa';
	const response = await apiV2
		.get(`/relation?userId=${id}&relationType=${relationType}`)
		.catch(() => {
			return [];
		});
	if (response.data?.length > 0) {
		return response;
	} else {
		return [];
	}
};

export const getContributorApprovedChildren = async (children) => {
	const connectedChildren = children.filter((child) => child.agencyId);
	const childList = children.filter((child) => !child.agencyId);
	const hasPermission = [];

	if (connectedChildren.length > 0) {
		connectedChildren.forEach((child) => {
			if (child?.permissions?.includes('mgmt_contributor')) {
				hasPermission.push(child);
			}
		});
	}
	const updatedList = childList.concat(hasPermission);
	store.dispatch(setContributorChildren(updatedList));
};
