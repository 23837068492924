import {
	createUser,
	updateUser,
	getUser,
	getRelationsList,
	reinviteUser,
	getCasaPermissions,
	getCasaUserPermissions,
} from './';
import store from '../redux/store';
import {
	setChild,
	setChildren,
	setChildrenHash,
	setChildrenLoaded,
	setListLoader,
	setAllCasaPermissions,
	setAllCasaUserPermissions,
	setInitialCasaPermissions,
} from '../redux';
import {
	getAge,
	mixpanelTrack,
	mixpanelTrackInviteSent,
	includeType,
	hashList,
} from '../helpers';
import { apiV2 } from '../axios';
import { getLocationAndUpload } from 'services';

export const inviteChild = async (childId) => {
	return await updateUser({ sendInvitation: true }, childId).then(() => {
		mixpanelTrackInviteSent('sent', { type: 'child', id: childId });
	});
};

const addChildTrack = (child) => {
	const userAgency = store?.getState()?.user.agency?.id;
	const eventParams = {
		updated: new Date(),
		age: getAge(child.dob),
	};
	if (userAgency) {
		eventParams.agency = userAgency;
	}
	if (child.gender) {
		eventParams.gender = child.gender;
	}
	mixpanelTrack('child.add', eventParams);
};

export const addChild = async (child) => {
	child.onboardDate = new Date();
	child.type = 'child';

	return await createUser(child).then(async (res) => {
		const childId = res.data?.id;

		if (child.file) {
			const response = await getLocationAndUpload(child.file, childId);
			const reqbody = { photo: response, id: childId };
			await updateChild(reqbody);
		}
		await setChildrenList();
		addChildTrack(child);
		return res;
	});
};

export const updateChild = async (child) => {
	let reqBody = {};

	const { id, photo, email, schoolId, status } = child;

	if (includeType(['social-worker'])) {
		reqBody = { id, photo };
		if (email) {
			reqBody.email = email;
		}

		if (schoolId) {
			reqBody.schoolId = schoolId;
		}
	} else {
		reqBody = { ...child };
	}

	let result = await apiV2.put(`/user/${id}`, reqBody).then(async (res) => {
		await setChildrenList();
		return res;
	});
	// TODO: if the child has a photo, use v2 to attach photo URL to result
	if (email && status === 'invited') {
		await inviteChild(id);
	}
	return result;
};

export const getChildSocialWorkers = async (childId) => {
	return await getRelationsList(childId, 'social-worker');
};

export const getChildAgencyUsers = async (childId) => {
	const agencyUsers = await getChildSocialWorkers(childId);
	const agencyUserIds = [];
	const relationIds = {};
	agencyUsers?.data?.forEach?.(({ id, relationId }) => {
		agencyUserIds.push(id);
		relationIds[id] = relationId;
	});
	return { agencyUserIds, relationIds };
};

export const addAgencyUsersToChild = async (child) => {
	try {
		const { agencyUserIds } = await getChildAgencyUsers(child?.id);
		return {
			...child,
			agencyUserIds,
		};
	} catch (_err) {
		// NOTE: Call can fail if no SWs are assigned to the child.
		// In these cases, return the child object so that the promise array
		// does not throw errors.
		return child;
	}
};

export const getChildrenList = async () => {
	let childList = await apiV2
		.get(`/child`)
		.then(async (res) => {
			if (res.data) {
				const children = res.data.filter((val = {}) => {
					if (!val.id) return false;
					return !(val.archived || val.exitedCare);
				});

				/*	const promiseArray = children.map((child) =>
					addAgencyUsersToChild(child)
				);*/

				return children;
			}
			return [];
		})
		.catch((err) => {
			return { error: true, message: err };
		});
	return childList;
};

export const setChildrenList = async () => {
	store.dispatch(setListLoader(true));
	store.dispatch(setChildren([]));
	store.dispatch(setChildrenHash({}));
	store.dispatch(setChildrenLoaded(false));

	const children = await getChildrenList();
	// TODO: sort results before dispatching
	children && store.dispatch(setChildren(children));
	children && store.dispatch(setChildrenHash(hashList(children)));
	store.dispatch(setChildrenLoaded(true));
	store.dispatch(setListLoader(false));
	return children;
};

export const getChild = async (childId) => {
	const { data } = await getUser(childId);
	return data;
};

export const getCurrentChild = async (childId, { fetch: shouldFetch } = {}) => {
	const user = store.getState().user.user;
	if (!shouldFetch) {
		const children = store.getState?.()?.lifebooks?.childrenHash || {};
		let child = children[childId];
		if (child) {
			store.dispatch(setChild(child));

			if (user.type === 'casauser') {
				store.dispatch(setInitialCasaPermissions('clear'));
				store.dispatch(setAllCasaPermissions('clear'));
				store.dispatch(setAllCasaUserPermissions('clear'));
			}
			if (user.type === 'casauser' && child.agencyId) {
				await getCasaPermissions(user.casaId, childId, user.id);
				await getCasaUserPermissions(user.casaId, childId, user.id);
			} else {
				store.dispatch(setAllCasaPermissions('all'));
				store.dispatch(setAllCasaUserPermissions('all'));
			}
			return child;
		}
	}

	const result = await getChild(childId);
	store.dispatch(setChild(result));
	return result;
};

export const getPostActivity = async ({
	childId,
	postId,
	lastPostActivityId,
}) => {
	let query = '?';
	if (postId) {
		query += `postId=${postId}`;
	} else {
		query += `childId=${childId}`;
	}
	if (lastPostActivityId) {
		query += `&lastPostActivityId=${lastPostActivityId}`;
	}
	return await apiV2.get(`/post/activity${query}`);
};

export const exitCare = async (childId, reasonCode, pass) => {
	// uses auth verify header //
	return await apiV2.put(`/child/${childId}/exit`, { reasonCode, pass });
};

export const resendExitCare = async (childId) => {
	return await apiV2.post(`/child/${childId}/exit/resend`);
};

export const archiveChild = async (childId, pass) => {
	// uses auth-verify header //
	return await apiV2.put(`/child/${childId}/archive`, { pass });
};

export const reInviteChild = async (childId) => {
	const result = await reinviteUser(childId).then(() => {
		mixpanelTrackInviteSent('resent', { type: 'child', id: childId });
	});
	return result;
};

export const cokConnect = async (body, userId, config) => {
	await apiV2.put(`/user/${userId}`, body, config).then((res) => {
		setChildrenList();
		return res;
	});
};
