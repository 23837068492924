import { usePasswordConfirm, useSetState } from 'helpers';
import useExportPdfCallbacks from '../../../utils/useExportPdfCallbacks';
import ExportPdfInfo from './ExportPdfInfo';
import * as Comp from 'components';
import styles from '../ChildAdmin.module.css';

const defaultState = {
	reason: '',
	receiverName: '',
	receiverRelation: '',
};

export default function ExportPdfForm({ open, setOpen }) {
	const onPasswordConfirm = usePasswordConfirm();
	const [state, setState] = useSetState({ ...defaultState });
	const getState = () => state;

	const { onExportPdf, handleValidate } = useExportPdfCallbacks({
		getState,
		setOpen,
	});

	const handleChange = (e) => {
		const { value, name } = e?.target || {};
		setState({ [name]: value });
	};

	return (
		<div className={styles.exportPdfFormRoot}>
			<div>
				<ExportPdfInfo />
				<Comp.TextFieldRow
					name='receiverName'
					value={state.receiverName}
					label='Recipient Name'
					onChange={handleChange}
					required
				/>
				<Comp.TextFieldRow
					name='receiverRelation'
					value={state.receiverRelation}
					label='Relation'
					onChange={handleChange}
					required
					tooltip={`Recipient's relation to the child.`}
				/>
				<Comp.TextAreaRow
					name='reason'
					className={styles.exportPdfFormField}
					value={state.reason}
					rows={5}
					rowsMax={5}
					label='Reason'
					onChange={handleChange}
					required
				/>
			</div>
			<div className={styles.exportPdfFormButtonContainer}>
				<Comp.SecondaryButton onClick={() => setOpen(false)}>
					Cancel
				</Comp.SecondaryButton>
				<Comp.PrimaryButton
					onClick={() => {
						if (handleValidate()) {
							onPasswordConfirm((e) => onExportPdf(e));
						}
					}}>
					Export
				</Comp.PrimaryButton>
			</div>
		</div>
	);
}
