import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserStateId } from 'redux/selectors';
import { updateAgencyUser, getAgencyUsers, archiveAgencyUser } from 'services';
import { errors } from 'helpers';
import * as helper from 'helpers/hooks';
import { PrimaryButton, SecondaryButton, DeleteButton } from 'components';

export const useGetAdminButtons = (getBody) => {
	const history = useHistory();
	const { setLoader, setSnackbar, setSnackbarError } =
		helper.useCreateDispatches();
	const { adminId } = useParams();
	const userId = useSelector(getUserStateId);
	const isOwnUser = adminId === userId;
	const onPasswordConfirm = helper.usePasswordConfirm();
	const roleSelectRedirect = helper.useRoleSelectRedirect();

	const errorHandler =
		(error) =>
		({ response }) => {
			setSnackbarError({ response, ...error }, { log: true });
		};

	function CancelButton() {
		const onClick = () => history.goBack();
		return <SecondaryButton onClick={onClick}>Cancel</SecondaryButton>;
	}

	function ArchiveButton() {
		const onDelete = async (pass) => {
			setLoader(true);
			archiveAgencyUser(adminId, pass)
				.then(async () => {
					setSnackbar({
						variant: 'success',
						message: 'Admin removed successfully',
					});
					await getAgencyUsers();
					history.push('/admins/');
				})
				.catch(errorHandler(errors.USER_ARCHIVE('Admin')))
				.finally(() => {
					setLoader(false);
				});
		};

		return (
			!isOwnUser && (
				<DeleteButton onClick={() => onPasswordConfirm((e) => onDelete(e))}>
					Delete
				</DeleteButton>
			)
		);
	}

	function SaveButton() {
		const onClick = async () => {
			setLoader(true);
			const reqBody = getBody();

			updateAgencyUser(reqBody)
				.then(async () => {
					if (reqBody.role) {
						roleSelectRedirect(reqBody.role, reqBody.id);
					}
				})
				.catch(errorHandler(errors.USER_SAVE('Admin')))
				.finally(() => {
					setLoader(false);
				});
		};

		return <PrimaryButton onClick={onClick}>Save Changes</PrimaryButton>;
	}

	return [
		<CancelButton key='cancel' />,
		<ArchiveButton key='archive' />,
		<SaveButton key='save' />,
	];
};
