import { useState } from 'react';
import { useCreateDispatches } from 'helpers/hooks';
import {
	PrimaryButton,
	SecondaryButton,
	PasswordField,
	TextField,
} from 'components';

const PasswordConfirm = (props) => {
	const {
		onSubmit,
		showToggle,
		show,
		message,
		onFailure,
		maxAttempts,
		getAttempts,
		setAttempts,
		keepLoader = true,
		secondaryParams: { secondaryCheck, secondaryLabel, secondaryError } = {},
	} = props;
	const [input, setInput] = useState('');
	const [secondaryInput, setSecondaryInput] = useState('');
	const [error, setError] = useState(false);
	const { setLoader } = useCreateDispatches();

	const validateSecondary = () => {
		if (
			!secondaryCheck ||
			secondaryCheck.toLowerCase() === secondaryInput.toLowerCase()
		) {
			return true;
		} else {
			return false;
		}
	};

	const submit = async () => {
		if (validateSecondary()) {
			setLoader(true);
			try {
				await onSubmit(input);
				showToggle(false);
			} catch (e) {
				if (onFailure) {
					if (!maxAttempts || !getAttempts || !setAttempts) return onFailure();

					const attempts = getAttempts();
					if (attempts >= maxAttempts) {
						onFailure();
					} else {
						setAttempts(attempts + 1);
						const attemptsMessage = `. Remaining attempts: ${
							maxAttempts - attempts
						}`;
						setError(`Invalid password${attemptsMessage}`);
					}
				} else {
					setError('Invalid password');
				}
			} finally {
				keepLoader && setLoader(false);
			}
		} else {
			setError(secondaryError);
		}
	};

	const enterSubmit = (e) => {
		if (e.keyCode === 13) {
			submit();
		}
	};

	return (
		<div
			className={`password-confirm-container ${
				!show ? 'password-confirm-hide' : ''
			}`}>
			<div className='password-confirm-card'>
				<h3>
					{message ? message : 'Please confirm your password to continue'}
				</h3>
				{secondaryLabel ? (
					<TextField
						label={secondaryLabel}
						onChange={(e) => {
							setSecondaryInput(e.target.value);
						}}
						autoFocus={Boolean(secondaryLabel)}
						value={secondaryInput}
						style={{ width: '85%' }}
					/>
				) : null}
				<PasswordField
					label='Password'
					onChange={(e) => {
						setInput(e.target.value);
					}}
					autoFocus={true}
					value={input}
					style={{ width: '85%' }}
					onKeyDown={(e) => enterSubmit(e)}
					inputProps={{ autoComplete: 'new-password' }}
				/>
				<div className='password-confirm-error'>{error || null}</div>
				<div className='password-confirm-button-container'>
					<SecondaryButton onClick={() => showToggle(false)}>
						Cancel
					</SecondaryButton>
					<PrimaryButton onClick={submit}>Submit</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

export default PasswordConfirm;
