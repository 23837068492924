import { useSelector } from 'react-redux';
import { apiV2 } from '../axios';
import { store, setSnackbar, setNsps, setNspsHash } from '../redux';
import { getPostsRoot } from '../redux/selectors';
import { postQueryBuilder, mixpanelTrackPost } from '../helpers';
import { getLocationAndUpload } from './';
import { postChildVault, editVaultPost } from './';

export const createImageRequest = async (reqBody, fileBody) => {
	const { newFile, childId, photos } = fileBody;
	if (!newFile) return reqBody;
	let photoArray = [];
	let promiseArray = [];
	photos.forEach((file) => {
		if (typeof file === 'string') {
			//string is already upload location
			photoArray.push(file);
		} else {
			promiseArray.push(
				getLocationAndUpload('child', file.file, childId, newFile)
			);
		}
	});

	let photoLocationArray = await Promise.all(promiseArray);
	reqBody.photos = [...photoArray, ...photoLocationArray];
	return reqBody;
};

export const checkDateDayOld = (date) => {
	const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
	return new Date(date).getTime() > oneDay;
};

export const getPostList = async (childId, query) => {
	return await apiV2.get(`/post?childId=${childId}${postQueryBuilder(query)}`);
};

const filterPostsByNsp = (posts, childId, isNsp) => {
	const filteredPosts =
		posts?.filter((val) => {
			const isChildDraft = val.childId === childId;
			let isCorrectType;
			if (isNsp) {
				isCorrectType = val.nsp ? true : false;
			} else {
				isCorrectType = val.nsp ? false : true;
			}
			return isChildDraft && isCorrectType;
		}) || [];
	return filteredPosts;
};

export const getPendingPostList = async (childId, query) => {
	return await apiV2.get(
		`/post/pending?childId=${childId}${postQueryBuilder(query)}`
	);
};

export const getTimelinePendingPosts = async (childId, query) => {
	return await getPendingPostList(childId, query).then((res) => ({
		data: { posts: filterPostsByNsp(res.data?.posts || [], childId, false) },
	}));
};

export const getNspPendingPosts = async (childId, query) => {
	return await getPendingPostList(childId, query).then((res) => ({
		data: { posts: filterPostsByNsp(res.data?.posts || [], childId, true) },
	}));
};

export const getServicePostList = async (childId, query) => {
	return await apiV2.get(
		`/post/nsp?childId=${childId}${postQueryBuilder(query)}`
	);
};

export const getPost = (postId) => {
	return apiV2
		.get(`/post/${postId}`)
		.then((res) => res.data)
		.catch((err) => {
			const { data } = err.response || {};
			store.dispatch(setSnackbar({ variant: 'error', message: data?.message }));
			throw err;
		});
};

export const addPost = async (body) => {
	return await apiV2.post('/post/', body).catch((err) => {
		const { data } = err.response || {};
		store.dispatch(setSnackbar({ variant: 'error', message: data?.message }));
		return { error: true, response: data };
	});
};

export const updatePost = async (body) => {
	return await apiV2.put(`/post/${body.postId}`, body).catch((err) => {
		const { data } = err.response;
		store.dispatch(setSnackbar({ variant: 'error', message: data.message }));
		return { error: true, response: data };
	});
};

export const convertDraft = async (body) => {
	await postDraft(body);
	return await apiV2
		.post(`/post/draft/${body.postId}/publish`, body)
		.catch((err) => {
			const { data } = err.response;
			store.dispatch(setSnackbar({ variant: 'error', message: data.message }));
			return { error: true, response: data };
		});
};

const getPostRequestFunction = (post, root, postType) => {
	if (post.postDraftId) {
		return convertDraft;
	}

	if (root === 'vault' && post?.postId) {
		return editVaultPost;
	}

	if (root === 'vault' || postType === 'vault') {
		return postChildVault;
	}

	if (!!post?.postId) {
		return updatePost;
	}

	return addPost;
};

export const useSubmitPost = () => {
	const root = useSelector(getPostsRoot);
	return async (reqBody, postType) => await submitPost(reqBody, root, postType);
};

export const submitPost = async (reqBody, root = 'lifebook', postType) => {
	const submitRequest = getPostRequestFunction(reqBody, root, postType);
	return await submitRequest(reqBody, root);
};

export const postDraft = async (reqBody) => {
	if (reqBody.note) {
		reqBody.metadata.noteDraft = reqBody.note;
		delete reqBody.note;
	}
	return await apiV2.post('/post/draft', reqBody);
};

export const archivePost = async (postId, pass) => {
	// uses auth-verify header //
	return await apiV2.delete(`/post/${postId}`, { pass });
};

export const approvePost = async (post) => {
	const { id } = post;
	return await apiV2.post(`/post/${id}/approve`).then((res) => {
		mixpanelTrackPost('approve', post, res?.data);
		return res;
	});
};

export const fetchDrafts = async () => {
	return await apiV2.get(`/post/draft`).then((res) => {
		const drafts =
			res.data?.map((draft) => {
				draft.postDraftId = draft.id;
				delete draft.id;
				return draft;
			}) || [];

		return drafts;
	});
};

export const getDrafts = async (childId) => {
	return await fetchDrafts().then((res) => ({
		data: { posts: filterPostsByNsp(res, childId) },
	}));
};

export const getNspDrafts = async (childId) => {
	return await fetchDrafts().then((res) => ({
		data: { posts: filterPostsByNsp(res, childId, true) },
	}));
};

export const deleteDraft = async (draftId) => {
	return await apiV2.delete(`/post/draft/${draftId}`);
};

export const requestPostChange = async (post, changeRequestMessage) => {
	const { id } = post;
	return await apiV2
		.post(`/post/${id}/changerequest`, {
			changeRequestMessage,
		})
		.then((res) => {
			mixpanelTrackPost('change', post, res?.data);
			return res;
		});
};

export const moveToVault = async (postId, moveToVaultReason) => {
	return await apiV2.post(`/post/${postId}/movetovault`, {
		moveToVaultReason,
	});
};

export const moveFromVault = async (postId) => {
	return await apiV2.post(`/vault/post/${postId}/movetotimeline`);
};

export const moveServicePost = async (postId, shouldDisplay) => {
	return await apiV2.put(`/post/${postId}`, {
		displayNSPInTimeline: shouldDisplay,
	});
};

export const getNspTemplates = async () => {
	let nsps = [];
	const reduxNsps = store?.getState()?.lifebooks?.nsps;
	if (reduxNsps?.length) {
		nsps = reduxNsps;
	} else {
		nsps = await apiV2
			.get('/nsp')
			.then((res) => {
				store.dispatch(setNsps(res.data));
				const nspsHash = res?.data.reduce((acc, nsp) => {
					acc[nsp.id] = nsp;
					return acc;
				}, {});
				store.dispatch(setNspsHash(nspsHash));
				return res.data;
			})
			.catch(() => {
				return [];
			});
	}
	return nsps;
};
